import React from 'react'
import { Link } from 'gatsby'
import { HomeIcon, RulesIcon, InfoIcon } from '@images/icons'

const NavLinks = ({ specialClass }) => (
	<ul className={specialClass}>
		<li>
			<Link to='/'><HomeIcon className="header-icon" />Home </Link>
		</li>
		<li>
			<Link to='/rules'> <RulesIcon className="header-icon" />DAO Rules</Link>
		</li>
		{/* <li>
			<Link to='/'>Galleries</Link>
		</li> */}
		<li>
			<Link to='/about'><InfoIcon className="header-icon" />About </Link>
		</li>
	</ul>
)

export default NavLinks
