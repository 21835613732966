import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Footer = () => (
	<footer className='bg-dark footer'>
		<div className='wrapper footer__inner'>
			{/* <div className='footer__input-container'>
				<p>Apply to join us today!</p>
				<div>
					<input
						type='text'
						name='email@example.com'
						placeholder='email@example.com'
					/>
					<button>APPLY</button>
				</div>
			</div> */}

			<div className='footer__nav'>
				<div className='footer__logo'>
					<div>
						<StaticImage src='../../images/logo-png.png' alt='DPS Logo' />
					</div>

					<p>&#169; 2021 DPS</p>
				</div>
				<div className='footer__links'>
					<h3>Community</h3>
					<ul className='footer__nav-list'>
						<li>
							<a
								href='https://twitter.com/DefiantPixel'
								target='_blank'
								rel='noreferrer'
							>
								Twitter
							</a>
						</li>
						<li>
							<a
								href='https://app.daohaus.club/dao/0x64/0x39b9317a1400f29d00224eca6f282ce09cc21f9b'
								target='_blank'
								rel='noreferrer'
							>
								DAO
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
)

export default Footer
