import React from 'react'

export const Hamburger = () => (
	<svg
		viewBox='0 0 15 15'

		xmlns='http://www.w3.org/2000/svg'
		width='15'
		height='15'
	>
		<path d='M0 3.5h15m-15 8h15m-15-4h15' stroke='currentColor'></path>
	</svg>
)

export const Times = () => (
	<svg
		viewBox='0 0 15 15'

		xmlns='http://www.w3.org/2000/svg'
		width='15'
		height='15'
	>
		<path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor'></path>
	</svg>
)

export const Instagram = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='prefix__icon prefix__icon-tabler prefix__icon-tabler-brand-instagram'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='#222429'

		strokeLinecap='round'
		strokeLinejoin='round'
	>
		<path d='M0 0h24v24H0z' stroke='none' />
		<rect x={4} y={4} width={16} height={16} rx={4} />
		<circle cx={12} cy={12} r={3} />
		<path d='M16.5 7.5v.001' />
	</svg>
)

export const Twitter = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='prefix__icon prefix__icon-tabler prefix__icon-tabler-brand-twitter'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		strokeWidth={1.5}
		stroke='#222429'

		strokeLinecap='round'
		strokeLinejoin='round'
	>
		<path d='M0 0h24v24H0z' stroke='none' />
		<path d='M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 00.497-3.753C20.18 7.773 21.692 5.25 22 4.009z' />
	</svg>
)

export const HomeIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="prefix__icon prefix__icon-tabler prefix__icon-tabler-home"
		width={22}
		height={22}
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"

		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M0 0h24v24H0z" stroke="none" />
		<path d="M5 12H3l9-9 9 9h-2M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
		<path d="M9 21v-6a2 2 0 012-2h2a2 2 0 012 2v6" />
	</svg>
)

export const RulesIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="prefix__icon prefix__icon-tabler prefix__icon-tabler-clipboard-list"
		width={22}
		height={22}
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"

		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M0 0h24v24H0z" stroke="none" />
		<path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2" />
		<rect x={9} y={3} width={6} height={4} rx={2} />
		<path d="M9 12h.01M13 12h2M9 16h.01M13 16h2" />
	</svg>
)

export const InfoIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="prefix__icon prefix__icon-tabler prefix__icon-tabler-info-circle"
		width={22}
		height={22}
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"

		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M0 0h24v24H0z" stroke="none" />
		<circle cx={12} cy={12} r={9} />
		<path d="M12 8h.01M11 12h1v4h1" />
	</svg>
)